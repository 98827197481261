import React, { useState } from "react"

export default () => {
  const [state, setState] = useState({
    username: "",
    password: ""
  })

  const signIn = () => {
    fetch("/loggain", {
      method: "POST",
      body: JSON.stringify(state),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.status === 200) {
          window.location.href = "/upload"
        } else {
          alert("Felaktigt användarnamn eller lösenord: " + res.status)
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <div>
      <div className="field">
        <p className="control has-icons-left has-icons-right">
          <input className="input" type="text" placeholder="Användarnamn" onChange={(e) => setState({ ...state, username: e.target.value })} />
          <span className="icon is-small is-left">
            <i className="fas fa-envelope"></i>
          </span>
          <span className="icon is-small is-right">
            <i className="fas fa-check"></i>
          </span>
        </p>
      </div>
      <div className="field">
        <p className="control has-icons-left">
          <input className="input" type="password" placeholder="Lösenord" onChange={(e) => setState({ ...state, password: e.target.value })} />
          <span className="icon is-small is-left">
            <i className="fas fa-lock"></i>
          </span>
        </p>
      </div>

      <button onClick={signIn} class="button">Logga in</button>
    </div>
  );
}
